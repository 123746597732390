import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/servicii/software-la-comanda/">
                                Dezvoltare de software la comandă
                                </Link>
                            </h3>
                            <p>Soluții software inovatoare, personalizate pentru afacerea ta. Tu ne dai provocarea, noi găsim si construim soluția corectă.</p>

                            <Link className="view-details-btn" to="/servicii/software-la-comanda/">
                                Vezi Detalii
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/servicii/testare-asigurarea-calitatii">
                                Testare și asigurarea calității
                                </Link>
                            </h3>

                            <p>Asigurați-vă calitatea și fiabilitatea software-ului cu serviciile noastre de testare complete.</p>
                            <Link className="view-details-btn" to="/servicii/testare-asigurarea-calitatii">
                                Vezi Detalii
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/servicii/mentenanta-aplicatii">
                                Management și mentenanță aplicații
                                </Link>
                            </h3>

                            <p>Păstrați-vă aplicațiile funcționând fără probleme cu serviciile noastre de management și mentenanță de aplicații.</p>
                            <Link className="view-details-btn" to="/servicii/mentenanta-aplicatii">
                                Vezi Detalii
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/servicii/transformare-digitala">
                                Transformare digitală
                                </Link>
                            </h3>

                            <p>Experimentați beneficiile transformării digitale: eficiență crescută, experiențe client mai bune și un avantaj competitiv pe piață.</p>
                            <Link className="view-details-btn" to="/servicii/transformare-digitala">
                                Vezi Detalii
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/servicii/dezvoltare-migrare-cloud/">
                                Dezvoltare și migrare cloud
                                </Link>
                            </h3>

                            <p>Echipa noastră de ingineri cloud vă ajută organizația să atingă o agilitate mai mare, o eficiență mai bună a costurilor și o productivitate mai crescută prin intermediul serviciilor noastre profesionale cloud și a expertizei noastre în DevOps.</p>
                            <Link className="view-details-btn" to="/servicii/dezvoltare-migrare-cloud/">
                                Vezi Detalii
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/servicii/management-proiect">
                                Management de proiect
                                </Link>
                            </h3>

                            <p>Eficientizați procesul de dezvoltare software și duceți proiectele la bun sfârșit cu echipa noastră experimentată de management de proiect.</p>
                            <Link className="view-details-btn" to="/servicii/management-proiect">
                                Vezi Detalii
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                                            <div className="single-solutions-box">
                                                <div className="icon">
                                                    <i className="flaticon-settings"></i>
                                                </div>
                    
                                                <h3>
                                                    <Link to="/servicii/training-it">
                                                    Training IT personalizat
                                                    </Link>
                                                </h3>
                    
                                                <p>Încurajați echipele să rămână la vârful industriei cu formări tehnice specializate adaptate nevoilor pieței.</p>
                                                <Link className="view-details-btn" to="/servicii/training-it">
                                                    Vezi Detalii
                                                </Link>
                                            </div>
                                        </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/servicii/securitate-cibernetica">
                                Securitate cibernetică
                                </Link>
                            </h3>

                            <p>Protejați-vă afacerea și datele sensibile în era digitală cu serviciile noastre de cibersecuritate de înaltă calitate.</p>
                            <Link className="view-details-btn" to="/servicii/securitate-cibernetica">
                                Vezi Detalii
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne